<script lang="ts">
    import { Button, Modal, Row } from 'Components/UI';
    import translations from 'Assets/i18n';
    import { applicationContextKey } from 'PublicationDependencies';
    import PublicationListService from 'Pages/PublicationListPage/Lib/Services/PublicationListService';
    import * as Style from 'Components/Style';
    import type { Nullable } from 'Lib/Utils/Nullable';
    import { getContext } from 'svelte';
    import type DependencyContainer from 'Lib/DependencyContainer';

    const translationContext = translations.pages.publicationList.deletionDialog;

    const applicationContext = getContext<DependencyContainer>(applicationContextKey);
    const publicationListService = applicationContext.get(PublicationListService);

    export let scenarioId: Nullable<string> = undefined;

    async function deleteScenarioAsync(): Promise<void> {
        if (scenarioId === undefined) {
            console.error('No scenario id was found.');
            return;
        }

        await publicationListService.deleteScenarioAsync(scenarioId);
        scenarioId = undefined;
    }
</script>

<Modal
    isShown={scenarioId !== undefined}
    title={translationContext.title}>
    <svelte:fragment slot="content">
        <p>{translationContext.description}</p>
    </svelte:fragment>
    <svelte:fragment slot="buttons">
        <Row horizontalAlign="end">
            <Button
                backgroundColor={Style.colors.negative}
                minWidth={Style.unset}
                on:click={deleteScenarioAsync}>
                {translationContext.confirmAction}
            </Button>
            <Button
                minWidth={Style.unset}
                on:click={() => (scenarioId = undefined)}>
                {translationContext.cancelAction}
            </Button>
        </Row>
    </svelte:fragment>
</Modal>
