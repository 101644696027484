<script lang="ts">
    import translations from 'Assets/i18n';

    import { applicationContextKey } from 'PublicationDependencies';
    import PublicationListContext from 'Pages/PublicationListPage/Lib/PublicationListContext';
    import PublicationListService from 'Pages/PublicationListPage/Lib/Services/PublicationListService';

    import { Column } from 'Components/UI';
    import ListItem from 'Pages/PublicationListPage/ListItem.svelte';
    import DeletionConfirmationDialog from 'Pages/PublicationListPage/DeletionConfirmationDialog.svelte';
    import Dropzone from 'Components/Dropzone.svelte';
    import { getContext } from 'svelte';
    import DependencyContainer from 'Lib/DependencyContainer';
    import SortableColumns from 'Pages/PublicationListPage/SortableColumns.svelte';
    import type { ColumnKey } from 'Pages/PublicationListPage/Lib/ColumnKeys';
    import type { KeyOrderPair } from 'Lib/Models/Order';
    import PasswordChangeDialog from 'Pages/PublicationListPage/PasswordChangeDialog.svelte';

    const translationContext = translations.pages.publicationList.list;
    const applicationContext = getContext<DependencyContainer>(applicationContextKey);

    const { scenarioListObservable, scenarioListSortingObservable, pendingUploadCountObservable } =
        applicationContext.get(PublicationListContext);
    const publicationListService = applicationContext.get(PublicationListService);
    const {scenarioIdForPasswordDialogObservable} = applicationContext.get(PublicationListContext);

    async function onFilesDroppedAsync(files: FileList): Promise<void> {
        await publicationListService.uploadScenariosAsync(files);
    }

    function onScenarioDeletion(scenarioId: string): void {
        deletableScenarioId = scenarioId;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async function onOrderChangedAsync(_: Array<KeyOrderPair<ColumnKey>>): Promise<void> {
        await publicationListService.updateScenarioListAsync();
    }

    let deletableScenarioId: string | undefined = undefined;

    let columns: Map<ColumnKey, string> = new Map<ColumnKey, string>([
        ['name', translationContext.header.itemName],
        ['createdBy', translationContext.header.createdBy],
        ['createdAt', translationContext.header.createdAt],
        ['updatedBy', translationContext.header.updatedBy],
        ['updatedAt', translationContext.header.updatedAt],
    ]);

    $: onlySortedByCreatedAtAsc =
        $scenarioListSortingObservable.length === 1 &&
        $scenarioListSortingObservable[0].key === 'createdAt' &&
        $scenarioListSortingObservable[0].order === 'asc';
    $: onOrderChangedAsync($scenarioListSortingObservable);
</script>

<Column
    horizontalAlign="center"
    width="100%">
    <div class="table-container">
        {#if $scenarioListObservable.length === 0 && $pendingUploadCountObservable === 0}
            <p>{translationContext.noContent}</p>
        {:else}
            <table>
                <thead>
                    <tr>
                        <SortableColumns
                            {columns}
                            bind:orders={$scenarioListSortingObservable} />
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {#if !onlySortedByCreatedAtAsc}
                        {#each Array($pendingUploadCountObservable) as _}
                            <ListItem />
                        {/each}
                    {/if}
                    {#each $scenarioListObservable as publishedItem}
                        <ListItem
                            item={publishedItem}
                            {onScenarioDeletion} />
                    {/each}
                    {#if onlySortedByCreatedAtAsc}
                        {#each Array($pendingUploadCountObservable) as _}
                            <ListItem />
                        {/each}
                    {/if}
                </tbody>
            </table>
        {/if}
    </div>
</Column>
<PasswordChangeDialog bind:scenarioId={$scenarioIdForPasswordDialogObservable}/>
<DeletionConfirmationDialog bind:scenarioId={deletableScenarioId} />
<Dropzone onFilesDropped={onFilesDroppedAsync}>
    <Column
        horizontalAlign="center"
        verticalAlign="center">
        <span class="mdi mdi-upload-outline" />
        {translations.pages.publicationList.dragAndDropDescription}
    </Column>
</Dropzone>

<style>
    table {
        margin: 8px;
    }

    .table-container {
        background-color: rgb(244, 246, 248);
        border: solid 1px #e8e8e8;
        margin: 0 0 0 0;
        max-width: 1800px;
        width: 80%;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    }

    thead {
        background-color: var(--secondary-color);
        color: var(--secondary-text-color);
    }

    th {
        padding: 8px;
        text-align: left;
    }

    p {
        margin: 16px;
    }

    .mdi-upload-outline {
        font-size: 64px;
    }

    @media (max-width: 1080px) {
        .table-container {
            width: calc(100% - 32px);
        }
    }

    @media (max-width: 800px) {
        table {
            display: flex;
            flex-direction: column;
            align-items: stretch;
        }

        thead {
            display: none;
        }
    }
</style>
