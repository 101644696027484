<script lang="ts">
    import { applicationContextKey } from 'PublicationDependencies';
    import PresentationContext from 'Pages/PresentationPage/Lib/PresentationContext';
    import translations from 'Assets/i18n';
    import PresentationBootstrap from 'PresentationBoostrap/PresentationBootstrap.svelte';
    import { getContext } from 'svelte';
    import DependencyContainer from 'Lib/DependencyContainer';
    import type { Nullable } from 'Lib/Utils/Nullable';

    const translationContext = translations.pages.presentation;
    const applicationContext = getContext<DependencyContainer>(applicationContextKey);

    const presentationContext = applicationContext.get(PresentationContext);

    function preventClose(event: BeforeUnloadEvent): Nullable<string> {
        if (presentationContext.presentationWritable && presentationContext.presentationLoaded) {
            event.returnValue = translationContext.leaveConfirmationText; // Gecko, Trident, Chrome 34+
            return translationContext.leaveConfirmationText; // Gecko, WebKit, Chrome <34
        }
        return undefined;
    }

    document.addEventListener('fullscreenchange', () => {
        for (let header of document.getElementsByTagName('header')) {
            header.style.display = document.fullscreenElement ? 'none' : '';
        }
    });
</script>

<svelte:window on:beforeunload={preventClose} />

<PresentationBootstrap />

<style>
    @media (display-mode: fullscreen) {
        :global(header) {
            display: none !important;
        }

        :global(main) {
            margin: 0 !important;
        }
    }
</style>
