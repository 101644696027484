<script lang="ts">
    import { applicationContextKey } from 'PublicationDependencies';
    import UserService from 'Lib/Services/UserService';
    import { Button, Column, Title } from 'Components/UI';
    import Text from 'Components/Text.svelte';
    import translations from 'Assets/i18n';
    import { getContext } from 'svelte';
    import DependencyContainer from 'Lib/DependencyContainer';

    const applicationContext = getContext<DependencyContainer>(applicationContextKey);
    const userService = applicationContext.get(UserService);
    const translationContext = translations.pages.login;

    export let title: string = '';

    let name: string;
    let password: string;
    let didLoginFailed: boolean;

    async function handleLoginAsync(): Promise<void> {
        didLoginFailed = !(await userService.loginAsync(name, password));
        if (!didLoginFailed) {
            name = '';
        }
        password = '';
    }
</script>

<form on:submit|preventDefault={handleLoginAsync}>
    <Column horizontalAlign="center">
        <Title
            padding="16px"
            size="2em">
            {title}
        </Title>
        <Column verticalAlign="center">
            <label for="emailField">{translationContext.fields.username}</label>
            <input
                id="emailField"
                autofocus
                minlength="1"
                placeholder={translationContext.fields.username}
                required
                type="text"
                bind:value={name} />
        </Column>
        <Column verticalAlign="center">
            <label for="passwordField">{translationContext.fields.password}</label>
            <input
                id="passwordField"
                minlength="1"
                placeholder={translationContext.fields.password}
                required
                type="password"
                bind:value={password} />
        </Column>
        <Button
            margin="16px"
            type="submit">
            {translationContext.loginAction}
        </Button>

        {#if didLoginFailed}
            <Text
                color="red"
                padding="8px">
                {translationContext.loginFailed}
            </Text>
        {/if}
    </Column>
</form>

<style>
    input {
        margin: 4px;
        width: 15vw;
    }

    ::global(button) {
        width: 7.5vw;
    }
</style>
