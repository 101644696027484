<script lang="ts">
    import { applicationContextKey } from 'PublicationDependencies';
    import PublicationListService from 'Pages/PublicationListPage/Lib/Services/PublicationListService';
    import { Row } from 'Components/UI';
    import UserMenuItem from 'Menubars/Components/UserMenuItem.svelte';
    import tooltip from 'Lib/Utils/Tooltip';
    import translations from 'Assets/i18n';
    import { getContext } from 'svelte';
    import DependencyContainer from 'Lib/DependencyContainer';
    import Button from '../Components/Button.svelte';

    const translationContext = translations.pages.publicationList;
    const applicationContext = getContext<DependencyContainer>(applicationContextKey);

    const publicationListService = applicationContext.get(PublicationListService);

    let files: FileList;
    $: if (files) {
        publicationListService.uploadScenariosAsync(files);
    }
</script>

<div>
    <Row verticalAlign="center">
        <Button
            minWidth="32px"
            padding="0">
            <label use:tooltip={translationContext.actions.upload}>
                <span class="mdi mdi-upload-outline" />
                <input
                    accept=".actg"
                    multiple
                    type="file"
                    bind:files />
            </label>
        </Button>
        <UserMenuItem />
    </Row>
</div>

<style>
    .mdi {
        font-size: 32px;
    }

    input {
        display: none;
    }

    label {
        cursor: pointer;
    }
</style>
