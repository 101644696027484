<script lang="ts">
    import { applicationContextKey } from 'PublicationDependencies';
    import UserService from 'Lib/Services/UserService';
    import PublicationContext from 'Lib/PublicationContext';
    import { Button, Column, Dropdown, Row } from 'Components/UI';
    import translations from 'Assets/i18n';
    import LoginForm from 'Pages/LoginPage/LoginForm.svelte';
    import { getContext } from 'svelte';
    import DependencyContainer from 'Lib/DependencyContainer';
    import * as Styles from 'Components/Style';
    import type { UnsubscriberFunction } from 'Lib/EventDefinition';
    import type { Nullable } from 'Lib/Utils/Nullable';

    const translationContext = translations.global;
    const applicationContext = getContext<DependencyContainer>(applicationContextKey);

    let loginDialog: HTMLDialogElement;
    let loginUnsubscriber: Nullable<UnsubscriberFunction>;

    const { currentUserObservable } = applicationContext.get(PublicationContext);

    const userService = applicationContext.get(UserService);

    async function logoutAsync(): Promise<void> {
        await userService.logoutAsync();
    }

    function showLoginModal(): void {
        disposeLoginEvent();
        loginUnsubscriber = userService.onLogin.do(() => {
            closeLoginModal();
        });
        loginDialog.showModal();
    }

    function disposeLoginEvent(): void {
        loginUnsubscriber?.();
    }

    function closeLoginModal(): void {
        disposeLoginEvent();
        loginDialog.close();
    }

    function closeIfOutsideClickReceived(event: MouseEvent): void {
        if (event.target === loginDialog) {
            closeLoginModal();
        }
    }
</script>

{#if !$currentUserObservable}
    <Button
        minWidth="32px"
        padding="0"
        tooltipContent={translationContext.actions.login}
        on:click={showLoginModal}>
        <Row verticalAlign="center">
            <span class="mdi mdi-login" />
        </Row>
    </Button>
{:else}
    <Dropdown>
        <Button
            slot="toggle"
            minWidth="32px"
            padding="0"
            tooltipContent={translationContext.actions.profile}>
            <span class="mdi mdi-account-outline" />
        </Button>
        <div slot="dropdown">
            <Column horizontalAlign="center">
                <h3 class="userLabel">{$currentUserObservable.name}</h3>
                <Button
                    backgroundColor={Styles.unset}
                    padding="0"
                    textColor="black"
                    on:click={logoutAsync}>
                    <Row verticalAlign="center">
                        <span class="mdi mdi-logout" />
                        {translationContext.actions.logout}
                    </Row>
                </Button>
            </Column>
        </div>
    </Dropdown>
{/if}

<dialog
    bind:this={loginDialog}
    on:click={closeIfOutsideClickReceived}
    on:close={closeLoginModal}>
    <div class="dialogPadding">
        <LoginForm title={translationContext.actions.login} />
    </div>
</dialog>

<style>
    ::global(label) {
        cursor: pointer;
    }

    dialog {
        border-color: var(--primary-color);
        border-width: 0;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
        padding: 0;
    }

    dialog::backdrop {
        background-color: rgba(0, 0, 0, 0.33);
    }

    .dialogPadding {
        padding: 16px;
    }

    .userLabel {
        text-overflow: ellipsis;
        margin: 12px;
        color: black;
    }

    .mdi {
        font-size: 32px;
    }
</style>
