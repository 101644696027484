<script lang="ts">
    import translations from 'Assets/i18n';

    import {Button, Row} from 'Components/UI';

    import {Scenario, ScenarioWithFiles} from 'Generated/RestClient.g';
    import {getContext} from 'svelte';
    import DependencyContainer from 'Lib/DependencyContainer';
    import {applicationContextKey} from 'PublicationDependencies';
    import PublicationListService from 'Pages/PublicationListPage/Lib/Services/PublicationListService';

    import * as Style from 'Components/Style';
    import type {Nullable} from 'Lib/Utils/Nullable';
    import PublicationListContext from 'Pages/PublicationListPage/Lib/PublicationListContext';

    const translationContext = translations.pages.publicationList.list;

    const applicationContext = getContext<DependencyContainer>(applicationContextKey);
    const publicationListService = applicationContext.get(PublicationListService);
    const {scenarioIdForPasswordDialogObservable} = applicationContext.get(PublicationListContext);

    const dateTimeFormatter = new Intl.DateTimeFormat(navigator.language, {
        dateStyle: 'short',
        timeStyle: 'short',
    });

    export let item: Nullable<ScenarioWithFiles> = undefined;
    export let onScenarioDeletion: Nullable<(scenarioId: string) => void> = undefined;

    $: scenario = item as Scenario;
    $: files = scenario?.files;
    $: presentationFile = files?.presentationFiles[0];
    $: dataFiles = files?.dataFiles;

    async function openPasswordDialog(id: string) {
        $scenarioIdForPasswordDialogObservable = id;
    }
</script>

{#if item}
    <tr>
        <td>
            <details>
                <summary>
                    <b>{scenario.name ?? translationContext.unnamedPresentation}</b>
                </summary>
                <br/>
                {presentationFile}
                <br/>
                {#each dataFiles as dataSourceName}
                    {dataSourceName}
                    <br/>
                {/each}
            </details>
        </td>
        <td>
            <span class="label">{translationContext.header.createdBy}</span>
            {scenario.owner}
        </td>
        <td>
            <span class="label">{translationContext.header.createdAt}</span>
            {dateTimeFormatter.format(scenario.createdAt)}
        </td>
        <td>
            <span class="label">{translationContext.header.updatedBy}</span>
            {scenario.updatedBy ?? '-'}
        </td>
        <td>
            <span class="label">{translationContext.header.updatedAt}</span>
            {scenario.updatedAt !== undefined ? dateTimeFormatter.format(scenario.updatedAt) : '-'}
        </td>
        <td>
            <Row>
                <Button
                        backgroundColor={Style.unset}
                        minWidth="var(--button-min-width)"
                        on:click={()=>openPasswordDialog(scenario.id)}
                        tooltipContent={translations.pages.publicationList.passwordProtectionTooltip}
                >
                    {#if scenario.passwordProtected}
                        <span class="mdi mdi-lock"/>
                    {:else}
                        <span class="mdi mdi-lock-open-outline"/>
                    {/if}
                </Button>
                <Button
                        backgroundColor={Style.unset}
                        minWidth="var(--button-min-width)"
                        tooltipContent={translationContext.actions.openScenario}
                        on:click={() => publicationListService.openScenario(scenario.id)}>
                    <span class="mdi mdi-open-in-new"/>
                </Button>
                <Button
                        backgroundColor={Style.unset}
                        minWidth="var(--button-min-width)"
                        tooltipContent={translationContext.actions.copyScenarioURI}
                        on:click={async () => publicationListService.copyScenarioURIAsync(scenario.id)}>
                    <span class="mdi mdi-content-copy"/>
                </Button>
                <Button
                        backgroundColor={Style.unset}
                        minWidth="var(--button-min-width)"
                        tooltipContent={translationContext.actions.download}
                        on:click={() => publicationListService.downloadScenario(scenario.id)}>
                    <span class="mdi mdi-download-outline"/>
                </Button>
                <Button
                        backgroundColor={Style.unset}
                        minWidth="var(--button-min-width)"
                        tooltipContent={translationContext.actions.delete}
                        on:click={() => onScenarioDeletion?.(scenario.id)}>
                    <span class="mdi mdi-delete-outline"/>
                </Button>
            </Row>
        </td>
    </tr>
{:else}
    <tr class="disabled">
        <td><p class="skeleton-loader"/></td>
        <td><p class="skeleton-loader"/></td>
        <td><p class="skeleton-loader"/></td>
        <td>-</td>
        <td>-</td>
        <td>
            <Row>
                <Button
                        backgroundColor={Style.unset}
                        isDisabled={true}
                        minWidth="var(--button-min-width)">
                    <span class="mdi mdi-lock-outline disabled"/>
                </Button>
                <Button
                        backgroundColor={Style.unset}
                        isDisabled={true}
                        minWidth="var(--button-min-width)">
                    <span class="mdi mdi-open-in-new disabled"/>
                </Button>
                <Button
                        backgroundColor={Style.unset}
                        isDisabled={true}
                        minWidth="var(--button-min-width)">
                    <span class="mdi mdi-content-copy disabled"/>
                </Button>
                <Button
                        backgroundColor={Style.unset}
                        isDisabled={true}
                        minWidth="var(--button-min-width)">
                    <span class="mdi mdi-download-outline disabled"/>
                </Button>
                <Button
                        backgroundColor={Style.unset}
                        isDisabled={true}
                        minWidth="var(--button-min-width)">
                    <span class="mdi mdi-delete-outline disabled"/>
                </Button>
            </Row>
        </td>
    </tr>
{/if}

<style>
    b {
        font-weight: 600;
    }

    summary {
        cursor: pointer;
    }

    .disabled {
        filter: grayscale(1);
    }

    tr:not(:last-child) {
        border-bottom: 1px solid black;
    }

    td {
        padding: 8px;
        vertical-align: middle;
        min-width: 114px;
    }

    td:first-child {
        width: 100%;
    }

    :global(.disabled button:disabled) {
        box-shadow: none;
    }

    td:last-child {
        --button-min-width: 32px;
    }

    .skeleton-loader {
        min-height: 25px;
        background: #eee;
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
    }

    @keyframes shine {
        to {
            background-position-x: -200%;
        }
    }

    .mdi {
        font-size: 32px;
        color: #0078d7;
    }

    .label {
        display: none;
    }

    @media (max-width: 1080px) {
        td:last-child {
            --button-min-width: 24px;
        }

        span.mdi {
            font-size: 24px;
        }
    }

    @media (max-width: 800px) {
        tr {
            display: flex;
            flex-direction: column;
            justify-content: stretch;
        }

        .label {
            display: inline-block;
            width: 100px;
            font-weight: 600;
        }
    }
</style>
