<script lang="ts">
    import type { KeyOrderPair, Order } from 'Lib/Models/Order';

    export let columns: Map<string, string>;
    export let orders: Array<KeyOrderPair<string>>;
    export let isMultiSortAllowed = false;

    const ordersSequence: Array<Order | undefined> = [undefined, 'asc', 'desc'];

    function toggleSort(key: string): void {
        const currentOrder = getOrder(key);
        if (!isMultiSortAllowed) {
            orders = [];
        } else if (currentOrder !== undefined) {
            orders.splice(
                orders.findIndex(entry => entry.key == key),
                1,
            );
        }

        const nextOrder = getNextOrder(currentOrder);
        if (nextOrder !== undefined) {
            orders.push({
                key: key,
                order: nextOrder,
            });
        }

        orders = orders;
        columns = columns;
    }

    function getNextOrder(currentOrder: Order | undefined): Order | undefined {
        const nextOrderIndex = ordersSequence.indexOf(currentOrder) + 1;

        return ordersSequence[nextOrderIndex > ordersSequence.length ? 0 : nextOrderIndex];
    }

    function getOrder(key: string): Order | undefined {
        return orders.find(entry => entry.key === key)?.order;
    }
</script>

{#each columns as [key, columName]}
    {@const order = getOrder(key)}
    <th on:click={() => toggleSort(key)}>
        {columName}
        {#if order}
            <span
                class="mdi"
                class:mdi-arrow-down-bold-outline={order !== 'asc'}
                class:mdi-arrow-up-bold-outline={order === 'asc'} />
        {/if}
    </th>
{/each}

<style>
    th {
        cursor: pointer;
        user-select: none;
        padding: 8px 4px;
        text-align: left;
        height: 33px;
    }
</style>
